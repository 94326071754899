.core-tab {
  // position: fixed !important;
  width: 100%;
  z-index: 100;
  flex-grow: 1;
  .MuiAppBar-colorPrimary {
    color: $primary-color;
    background-color: #ffffff;
    .MuiTab-wrapper {
      text-transform: capitalize;
      font-weight: 600;
    }
    .PrivateTabIndicator-colorSecondary {
      background-color: $primary-color;
    }
  }
}

.MuiTabs-flexContainer {
  .MuiTab-textColorInherit.Mui-selected {
    color: orange;
  }
  .MuiTab-textColorInherit {
    color: #212121;
  }
}

.tab-header {
  margin-top: 67px;

  &.MuiAppBar-root {
    z-index: 2;
    @media screen and (min-width: 600px) {
      box-shadow: none;
      top: 87px;
      width: calc(100% - 288px);
      right: 24px;
    }
  }
}

.search-top-tab {
  margin-top: 105px;

  &.MuiAppBar-root {
    z-index: 2;
    @media screen and (min-width: 600px) {
      box-shadow: none;
      top: 87px !important;
      width: calc(100% - 288px);
      right: 24px;
    }
  }
}

.company-tabs-title {
  margin-top: 98px;
  height: 55px;

  &.MuiAppBar-root {
    z-index: 2;
    @media screen and (min-width: 600px) {
      box-shadow: none;
      top: 86px;
      height: 60px;
      width: calc(100% - 288px);
      right: 24px;
    }
  }
}

.dynamic-year-search-top-tab {
  margin-top: 128px;

  &.MuiAppBar-root {
    z-index: 70;
    @media screen and (min-width: 600px) {
      overflow: hidden;
      top: 5.65rem;
      width: calc(100% - 288px);
      right: 24px;
    }
  }
}

.calendar-policy-tab {
  margin-top: 0px;
  z-index: 0;
  &.MuiAppBar-root {
    z-index: 0;
    position: inherit;
    @media screen and (min-width: 600px) {
      margin-top: 0px !important;
      width: 100%;
      //width: calc(100% - 288px);
      //right: 24px;
    }
  }
}

.job-position {
  margin-top: -10px;

  &.MuiAppBar-root {
    z-index: -70;
    @media screen and (min-width: 600px) {
      z-index: -70000 !important;
      position: relative;
      overflow: hidden;
      top: -70px;
      width: calc(100%);
      right: 24px;
      margin-top: 60px;
    }
  }
}

.job-position-mobile {
  margin-top: -10px;

  &.MuiAppBar-root {
    z-index: -70;
    @media screen and (min-width: 600px) {
      z-index: -70000 !important;
      position: relative;
      overflow: hidden;
      top: 570px;
      width: calc(100%);
      right: 24px;
    }
  }
}

.tab-policy {
  margin-top: 148px;
  z-index: 100;
  &.MuiAppBar-root {
    z-index: 70;
    @media screen and (min-width: 600px) {
      margin-top: 147px !important;
      width: calc(100% - 288px);
      right: 24px;
    }
  }
}

.tab-reporting-form {
  margin-top: 177px;
  z-index: 100;
  &.MuiAppBar-root {
    z-index: 70;
    @media screen and (min-width: 600px) {
      margin-top: 186px !important;
      width: calc(100% - 288px);
      right: 24px;
    }
  }
}

.dynamic-tab-subheader {
  margin-top: 118px;

  &.MuiAppBar-root {
    z-index: 70;
    @media screen and (min-width: 600px) {
      overflow: hidden;
      top: 5.65rem;
      width: calc(100% - 288px);
      right: 24px;
    }
  }
}

.multidynamic-info-search-top {
  margin-top: 161px;

  &.MuiAppBar-root {
    z-index: 0;
    @media screen and (min-width: 600px) {
      overflow: hidden;
      top: 5.65rem;
      width: calc(100% - 288px);
      right: 24px;
    }
  }
}